import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../styles/InitialArchive.css';
import File from '../Components/File.js'
import Archive from '../assets/Archive.png';
import { getAuth, createUserWithEmailAndPassword, setPersistence, browserLocalPersistence, signInWithEmailAndPassword, signOut, onAuthStateChanged } from "firebase/auth";
import { collection, addDoc, setDoc, doc, getDoc, onSnapshot, query, orderBy, getDocs, limit, updateDoc, where, writeBatch } from 'firebase/firestore';
import { getAnalytics, logEvent } from 'firebase/analytics'; 
import { auth, firestore, fireStorage, analytics } from '../firebase-config';

function InitalArchive() {
    const navigate = useNavigate();
    const auth = getAuth();
    const [reserved, setReserved] = useState(false);
    const analytics = getAnalytics();
    
    useEffect(() => {
        const fetchUserDetails = async () => {
            logEvent(analytics, 'page_view', {
                page_title: 'InitialArchive',
              });
            onAuthStateChanged(auth, async (user) => {
                if (user) {
                    const userUID = user.uid;
                    const docRef = doc(firestore, 'users', userUID, 'archiveAccess', 'Genesis');
                    try {
                        const docSnap = await getDoc(docRef);
                        if (docSnap.exists()) {
                            const userDetails = docSnap.data();
                            if(!userDetails.access){
                                 setReserved(true);
                            }
                        }
                        else{
                            setReserved(true);
                        }
                    } catch (error) {
                        console.log(error)
                    }
                }
                else{
                    setReserved(true);
                }
            });
        }
        fetchUserDetails();
    }, []);

    return (
        <div className="containerIA">
            <div className="top-bar">
                <Link to="/" style={{ textDecoration: 'none' }}>
                    <button className="back-button">Back</button>
                </Link>
                <div className="purp-circle"></div>
            </div>
            <div className='split' style={{ marginTop: '0px' }}>
                <div className="p1">
                    <div className='genResContainer2'>
                        <p id='genResTitle2'>Genesis Archive</p>
                        {!reserved ? <button id='genResButtReserved2'>Reserved</button> : null}
                                {reserved ? <button id='genResButt2' onClick={() => {
                                    navigate('/ReserveAccess', {
                                        state: { res: true }
                                    });
                                }}>Reserve</button> : null}
                    </div>
                    <div className='ImgContainer'>
                        <img src={Archive} id="archive" />
                    </div>
                </div>
                <div className="p2">
                    <div className='details'>
                        <div className='genArchDescriptionContainer'>
                            <div className='genResContainer'>
                                <p id='genResTitle'>Genesis Archive</p>
                                {!reserved ? <p id='genResButtReserved'>Reserved</p> : null}
                                {reserved ? <button id='genResButt' onClick={() => {
                                    navigate('/ReserveAccess', {
                                        state: { res: true }
                                    });
                                }}>Reserve</button> : null}
                            </div>
                            <p id='genArchDescription'>
                                An Epoc Archive serves as a shared repository of our experiences and creations for a defined period in time. <br />
                                <br /> 
                                The Genesis archive is the first of its kind set to launch in 2025. <br /> 
                                <br /> 
                                By participating in an Epoc Archive, your experiences and creations can endure beyond your lifetime, helping to ensure your essence is not forgotten and lives on with humanity. <br /> 
                                <br /> 
                               
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='fullAccessContainer'>
                <p id='fullAccess'>Full access to this archive will be available when the app launches</p>
            </div>
        </div>
    );
}

export default InitalArchive;
