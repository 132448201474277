import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../styles/AppInisght.css';
import File from '../Components/File.js'
import Grid from '../assets/Grid.png';
import Timeline from '../assets/Timeline.png';
import { getAuth, createUserWithEmailAndPassword, setPersistence, browserLocalPersistence, signInWithEmailAndPassword, signOut, onAuthStateChanged } from "firebase/auth";
import { collection, addDoc, setDoc, doc, getDoc, onSnapshot, query, orderBy, getDocs, limit, updateDoc, where, writeBatch } from 'firebase/firestore';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { auth, firestore, fireStorage, analytics } from '../firebase-config';

function InitalArchive() {
    const navigate = useNavigate();
    const auth = getAuth();
    const [reserved, setReserved] = useState(false);
    const [options, setOptions] = useState(false);
    const analytics = getAnalytics();

    useEffect(() => {
        const onLoad = async () => {
            logEvent(analytics, 'page_view', {
                page_title: 'AppInisght',
            });
        }
        onLoad();
    }, []);

    return (
        <div className="containerIA">
            <div className="top-bar">
                <Link to="/" style={{ textDecoration: 'none' }}>
                    <button className="back-button">Back</button>
                </Link>
                <div className="purp-circle"></div>
            </div>
            <div className='split' style={{ marginTop: '0px' }}>
                <div className="p1">
                    <div className='detailsAI'>
                        <div className='detailsBlockAI'>
                            <div className='genArchDescriptionContainer'>
                                <div className='genResContainerAI'>
                                    <p id='genResTitle'>Inside The App</p>
                                </div>
                                <p id='subTitleAI'>Explore the key features and core functionalities.</p>
                                {options ? <div className='buttonsAI'>
                                    <button id='bf' onClick={() => { setOptions(false) }}>Collections</button>
                                    <button id='bt' >Archives</button>
                                </div> : null}
                                {!options ? <div className='buttonsAI'>
                                    <button id='bt'>Collections</button>
                                    <button id='bf' onClick={() => { setOptions(true) }}>Archives</button>
                                </div> : null}
                                {options ? <p id='genArchDescriptionAIDesktop'>
                                    Archives are where Collections are preserved, ensuring that our experiences, ideas, and creations stand the test of time.
                                    <br /><br />
                                    There are two types of archives:
                                    <br /><br />
                                    Epoc Archives: Curated by us, these archives represent specific periods in time. They're open to everyone, giving each person the chance to contribute their narrative.
                                    <br /><br />
                                    Community Archives: These exclusive archives can be created by anyone in the Epoc community, giving you the opportunity to contribute to themes or topics that resonate with you.
                                </p> : null}
                                {!options ? <p id='genArchDescriptionAIDesktop'>
                                    A Collection is a way to capture and express an experience, idea, or creation.
                                    Each Collection can include images, videos, text, and music, allowing you to
                                    fully showcase your unique experiences.
                                    <br /><br />
                                    Your Collections fill your profile, creating a personal timeline of your
                                    thoughts and moments. You can choose to keep them private or contribute
                                    them to any available archive.
                                </p> : null}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p2">
                    {options ?
                        <div className='ImgContainerAI'>
                            <div className='ImgContainerAISize'>
                                <img src={Timeline} id="imageAI" />
                            </div>
                            <div className='descImgAI'>
                                <p id='genArchDescriptionAIPhoneArch'>
                                    Archives are where Collections are preserved, ensuring that our experiences, ideas, and creations stand the test of time.
                                    <br /><br />
                                    There are two types of archives:
                                    <br /><br />
                                    Epoc Archives: Curated by us, these archives represent specific periods in time. They're open to everyone, giving each person the chance to contribute their narrative.
                                    <br /><br />
                                    Community Archives: These exclusive archives can be created by anyone in the Epoc community, giving you the opportunity to contribute to themes or topics that resonate with you.
                                </p>
                            </div>
                        </div> : null}
                    {!options ?
                        <div className='ImgContainerAI'>
                            <div className='ImgContainerAISize'>
                                <img src={Grid} id="imageAI" />
                            </div>
                            <div className='descImgAI'>
                                <p id='genArchDescriptionAIPhoneArch'>
                                    A Collection is a way to capture and express an experience, idea, or creation.
                                    Each Collection can include images, videos, text, and music, allowing you to
                                    fully showcase your unique experiences.
                                    <br /><br />
                                    Your Collections fill your profile, creating a personal timeline of your
                                    thoughts and moments. You can choose to keep them private or contribute
                                    them to any available archive.
                                </p>
                            </div>
                        </div> : null}
                </div>
            </div>
        </div>
    );
}

export default InitalArchive;
